<template>
  <div class="">
    <img class="notesbg" src="../assets/images/notesbg.png" alt="" />
    <div class="ptb15"></div>
    <div class="p15">
      <div>
        <div
          class="textwhite flex flex-column justify-center align-center iph5tb mtb70"
        >
          <div class="f35">干细胞之家</div>
          <div class="f55">大事记</div>
        </div>
      </div>
      <!--内容-->
      <div class="plr12 radius5 f17 positionr">
        <div
          class="bg0D1672 h68 radius5 margin10-t textwhite flex justify-start align-center p15 fontmin16 overFont"
        >
          2021年
          <img class="mlr12 z-index1" src="../assets/images/APP.png" alt="" />
          <span>APP全面改版上线</span>
        </div>
        <div
          class="bg0D1672 h68 radius5 margin10-t textwhite flex justify-start align-center p15 fontmin16 overFont"
        >
          2020年
          <img class="mlr12 z-index1" src="../assets/images/online.png" alt="" />
          <span>APP首次上线试运行</span>
        </div>
        <div
          class="bg0D1672 h68 radius5 margin10-t textwhite flex justify-start align-center p15 fontmin16 overFont"
        >
          2019年
          <img class="mlr12 z-index1" src="../assets/images/design.png" alt="" />
          <span>2019年设计开发APP</span>
        </div>
        <div
          class="bg0D1672 h68 radius5 margin10-t textwhite flex justify-start align-center p15 fontmin16 overFont"
        >
          2016年
          <img class="mlr12 z-index1" src="../assets/images/team.png" alt="" />
          <span>组建团队，开拓市场</span>
        </div>
        <div
          class="bg0D1672 h68 radius5 margin10-t textwhite flex justify-start align-center p15 fontmin16 overFont"
        >
          2015年
          <img class="mlr12 z-index1" src="../assets/images/survey.png" alt="" />
          <span>行业调研结束</span>
        </div>
        <div
          class="bg0D1672 h68 radius5 margin10-t textwhite flex justify-start align-center p15 fontmin16 overFont"
        >
          2013年
          <img
            class="mlr12 z-index1"
            src="../assets/images/partner.png"
            alt=""
          />
          <span>合伙人团队成立</span>
        </div>
        <div class="vertical-line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import "../assets/css/style.css";
@import "../assets/css/common.css";
</style>